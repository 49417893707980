import React, { useEffect, useState } from 'react';
import { post } from '../../api/requests/main';
import { useContext } from 'react';
import { Context } from "../../context/store";



const ChannelIframe = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [noAccess, setNoAccess] = useState(false);
  const [mainState, setMainState] = useContext(Context);

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const response = await post('channex-generate-iframe-url', { property: mainState.selected_Property });
        if (response.status == 202) {
          setNoAccess(true)
        } else if (response.status == 200) {
          setIframeUrl(response.data.iframeUrl);
        }
      } catch (error) {
        console.error('Error fetching iframe URL:', error);
      }
    };
    setIframeUrl(false)
    setNoAccess(false)
    fetchIframeUrl();
  }, [mainState.selected_Property]);


  if (noAccess) {
    return (
      <div className='d-center'>
        <h3>Access Restricted</h3>
        <p>Your current plan does not include this feature.</p>
        <p>Contact support to upgrade and gain access.</p>
      </div>
    );
  }
  return (
    <div>
      {iframeUrl ? (
        <iframe src={iframeUrl} width="100%" height="100%" style={{ height: "100%", minHeight: 'calc(100vh - 60px)' }} frameBorder="0" allowFullScreen></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ChannelIframe;
