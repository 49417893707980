import React, { useContext, useEffect, useState } from 'react'
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';
import { payment } from '../../helpers/getTotal';
import { formatDate } from '../../helpers/dateFormat';
function Expenses() {
    const d = new Date();
    const [mainState, setMainState] = useContext(Context);

    const [data, setData] = useState([]);
    const [m, setM] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y, setY] = useState(mainState.selectedDate.y || d.getFullYear());


    // maintnince

    // const 
    useEffect(() => {
        getcalender()
        setMainState({ type: 'selectedDate', payload: { 'y': Number(y), 'm': Number(m) } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [m, y, setY, setM, mainState.selected_Property])

    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })

        try {
            const response = await post('expenses/expenses-list', { 
                "year": y,
                property: mainState.selected_Property,
                "month": Number(m)
                });
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };

    const updateDate = (s) => {
        s.target.getAttribute('d-type') === "m" ?
            setY(Number(s.target.value))
            :
            setY(Number(s.target.value))
            ;
    }



    return (
        <>
            <div>
                <header className='container'>
                    <nav className='flex'>
                        <select className='form-select' onChange={updateDate} d-type="y" defaultValue={y}>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                        <select className='form-select' onChange={updateDate} d-type="m" defaultValue={m}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </nav>
                </header>

                <br />
                <br />
                <br />
            </div>
            <section className='container'>
                <div className='center'>
                    <h4>
                        Total Expenses this month <b>{payment(data)}</b>
                    </h4>
                </div>
                <table className='table custom table-white vc'>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((a, id) => {
                            return (
                                <tr className={'tr ' + a.type + " num" + id} key={"num" + id} >
                                    <td>{formatDate(a.date)}</td>
                                    <td>{a.name}</td>
                                    <td>{a.description}</td>
                                    <td>{Number(a.amount).toFixed(0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Expenses