import { country } from '../helpers/getCountry';

export function CheckIn(date1, date2, payment) {
  const checkin = new Date(date1);
  const checkout = new Date(date2);
  const daysbetween = (checkout.getTime() - checkin.getTime()) / (1000 * 3600 * 24);
  const paymentperday = payment / daysbetween;
  return paymentperday;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function maskPhone(phone) {
  let phoneStr = phone.toString();

  if (phoneStr.length < 5) {
    return phoneStr;
  }

  let start = Math.floor((phoneStr.length - 5) / 2);
  let end = start + 5;

  return phoneStr.substring(0, start) + '*****' + phoneStr.substring(end);
}


export function convertToObjectArray(input) {
  return Object.keys(input)
    .filter(key => !isNaN(key))
    .map(key => input[key]);
}

export function getUserPhoneDisplay(user, isStaff) {
  if (!user?.phone) {
    return <span>Phone: <b className="na">Not Provided</b></span>;
  }

  const phone = isStaff ? user.phone : maskPhone(user.phone);

  return (
    <span>
      Phone: {phone}
      <b className="a" onClick={() => { window.location.href = `/whatsapp?phone=${user.phone}`; }}>
        <i className="fa-brands fa-whatsapp"></i>
      </b>
    </span>
  );
};


export function getNationalityDisplay(user) {
  const nationality = user?.nationality || "Not Provided";
  const flagUrl = nationality !== "Not Provided"
    ? `https://flagcdn.com/w40/${nationality.toLowerCase()}.png`
    : "https://flagcdn.com/w40/un.png";

  return (
    <span style={{ alignItems: "center", display: "flex" }}>
      <span>Nationality:
        <img src={flagUrl} alt={`${nationality} flag`} width="20" height="15" />
        <b className='na'>{country(nationality)}</b>
      </span>
    </span>
  );
}


export function getUserEmailDisplay(user, resendEmail) {

  if (!user?.email || user?.email === "False") {
    return <div>Email: <b className='na'>Not Provided</b></div>;
  }

  if (!isValidEmail(user.email)) {
    return <div>Email: <b className='na'>Invalid</b></div>;
  }

  const emailStatus = user.verified ? "verified" : "pending";
  const verificationButton = user.verified
    ? null
    : <button className="a" style={{ textAlign: "end" }} onClick={resendEmail}>Send verification email</button>;

  return (
    <div className="flex all email">
      <span>Email: <span className='na mail'>{user.email}</span></span> <span className={emailStatus}>{user.verified ? "verified" : ""}</span>
      {verificationButton}
    </div>
  );
}

