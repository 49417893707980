import React, { useContext, useEffect, useState } from 'react'
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Channel from '../../pages/components/platform';

import BookStatus from '../sub-componments/Bookstatus';
import { NavLink } from 'react-router-dom';
function Bookinglist() {
    const [mainState, setMainState] = useContext(Context);
    const [data, setData] = useState([]);

    const today = new Date().toISOString().slice(0, 10);
    const fifteenDaysFromNow = new Date(today);
    const FourDaysTillNow = new Date(today);
    fifteenDaysFromNow.setDate(fifteenDaysFromNow.getDate() + 30);
    FourDaysTillNow.setDate(FourDaysTillNow.getDate());
    const startDate = FourDaysTillNow.toISOString().slice(0, 10);
    const endDate = fifteenDaysFromNow.toISOString().slice(0, 10);

    const [finalState, setFinalState] = useState('waitlist');


    const [finalDate, setFinalDate] = useState([new Date(startDate), new Date(endDate)]);
    useEffect(() => {
        getcalender()
    }, [finalDate, finalState, mainState.selected_Property])

    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })
        try {
            const response = await post('waitlist/list', {
                property: mainState.selected_Property,
                start: new Date(finalDate[0]).toISOString().slice(0, 10),
                end: new Date(finalDate[1]).toISOString().slice(0, 10),
                bookingStatus: finalState
            });
            if (response.status === 200) {
                setData(response.data)
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };


    return (
        <>

            <div className='container center'>
                <br />
                <br />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='container'>
                        <div className='flex'>
                            <h2>Booking Report</h2>
                            <div className=' end'>
                                <DateRangePicker onChange={setFinalDate} value={finalDate} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <select className="sm form-select" name="BookStatus" onChange={(s) => { setFinalState(s.target.value) }} defaultValue={finalState} >
                            <BookStatus />
                        </select>
                    </div>
                    <table className='table custom table-white vc'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Platform</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Bed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((a, id) => {
                                return <tr id={a.bid} key={a.id + 'activeDiv'} >
                                    <td className='nowrap'>
                                        <b className='a' onClick={() => { setMainState({ type: "userId", payload: a.gid }); }}> {a.name} </b>
                                        {a.phone &&
                                            <NavLink className="a table whatsapp" to={"/whatsapp?phone=" + a.phone} >
                                                <i className="fa-brands fa-whatsapp"></i>
                                            </NavLink>
                                        }
                                    </td>
                                    <td > <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> <Channel name={a.platform} /> </b> </td>
                                    <td >{a.bookingDate} </td>
                                    <td>{Number(a.amount).toFixed(2)}</td>
                                    <td> {a.bed_name} </td>

                                </tr>
                            })}
                        </tbody >
                    </table>
                </div>
            </div>

        </>
    )
}

export default Bookinglist