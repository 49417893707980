import * as React from "react";
import { post } from "../../../../api/requests/main";

function AddNewPayment({
    setnewPayment,
    setPaymentLinkPopup,
    PaymentRef,
    bid,
    amountRef,
    handleAmountChange,
    amount,
    handlePaymentChange,
    SubMethod,
    paymentMethod,
    mainState,
    uid,
    savePayment
}) {
    const [paymentOptions, setPaymentOptions] = React.useState([]);

    React.useEffect(() => {
        const fetchPaymentOptions = async () => {
            try {
                const response = await post("online/payment-options", { property: mainState.selected_Property });
                if (response.status === 200 && response.data.status) {
                    setPaymentOptions(response.data.options);
                }
            } catch (error) {
                console.error("Error fetching payment options", error);
            }
        };
        fetchPaymentOptions();
    }, []);

    return (
        <div className="popup">
            <div className="card child dark">
                <header>
                    <h4> Add new Payment -
                        <button disabled={!paymentOptions.includes('stripe')} className="Stripe pgw" onClick={() => { setPaymentLinkPopup("stripe", "booking"); }}> Stripe </button>
                        <button disabled={!paymentOptions.includes('nomod')} className="nomod pgw" onClick={() => { setPaymentLinkPopup("nomod", "booking"); }}> nomod </button>

                    </h4>
                    <span onClick={() => { setnewPayment(false); }} > <i className="fa-solid fa-xmark"></i> </span>
                </header>
                <section>
                    <form ref={PaymentRef}>
                        <input name='property' type='hidden' value={mainState.selected_Property} />

                        <div className="input-50">
                            <span>
                                Amount:
                                {paymentMethod === "pos" ? (
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#43ab55' }}>
                                        Please Collect {parseFloat(amountRef.current?.value) + parseFloat(amountRef.current?.value * 0.05)}
                                    </span>
                                ) : null}
                                <input
                                    onChange={handleAmountChange}
                                    ref={amountRef}
                                    value={amount}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="form-control"
                                    name="amount"
                                    placeholder="amount"
                                />
                            </span>
                            <input name="date" type="hidden" readOnly className="form-control" defaultValue={new Date().toISOString().substr(0, 10)} />
                        </div>
                        Type:
                        <select name="type" className="form-select" defaultValue="income">
                            <option value="income">Income</option>
                            {mainState?.user?.is_staff && (
                                <option value="outgoing">Outgoing</option>
                            )}
                        </select>
                        Payment Account:
                        <select name="paymentMethod" onChange={handlePaymentChange} className="form-select">
                            {mainState?.user?.is_staff ? (
                                <SubMethod selected_Property={mainState.selected_Property} />
                            ) : (
                                <>
                                    {mainState?.user?.username && <option value={mainState.user.username}>{mainState.user.username}</option>}
                                    <option value="POS">POS</option>
                                </>
                            )}
                        </select>

                        <div className="POS-fees">
                            {paymentMethod === "pos" && (<span>POS fees of 5% will be added</span>)}
                        </div>

                        <label>
                            Attachment
                            <input type="file" name="image" className="form-control" />
                        </label>

                        Description:
                        <textarea name="description" className="form-control" placeholder="Payment Description"></textarea>

                        <input name="name" type="hidden" defaultValue="booking" />
                        <input name="gid_id" type="hidden" defaultValue={uid} />
                        <input name="bid_id" type="hidden" defaultValue={bid} />
                    </form>
                </section>
                <footer className="actions">
                    <button className="btn btn-primary main" onClick={savePayment} disabled={!amount}> Save Payment </button>
                </footer>
            </div>
        </div>
    )
}

export default AddNewPayment;