import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Store } from './context/store';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import * as Sentry from "@sentry/react";


const enableStrictMode = process.env.REACT_APP_DEBUG === 'True';

// Sentry.init({
//   dsn: "https://e5fb8c5cb6d6fabe86547792a1cd529e@o4508411678687232.ingest.de.sentry.io/4508417624440912",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: [
//     "localhost",
//     /^https:\/\/app\.hostelmate\.co\//
//     // /^https:\/\/yourserver\.io\/api/
//   ],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  enableStrictMode ? (
    <React.StrictMode>
      <Store>
        <App />
      </Store>
    </React.StrictMode>
  ) : (
    <Store>
      <App />
    </Store>
  )
);

reportWebVitals();
