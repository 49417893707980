import * as React from "react";
import { useContext, useState } from 'react';
import { Outlet, useOutletContext } from "react-router-dom";
import { Context } from "./context/store";
import { redirect } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { ListProperties } from '../src/pages/sub-componments/properyties.js';
import { ToastContainer } from 'react-toastify';
import Notifi from './components/common/noti/notification.js'
import Loader from './components/common/noti/loader.js'
import ViewBooking from './components/common/popups/viewBooking.js';
import ViewUser from './components/common/popups/viewUser.js';
import 'react-loading-skeleton/dist/skeleton.css'
import { post } from "./api/requests/main.js";

function Main() {
    const [mainState, setMainState] = useContext(Context);
    const [menu, setMenu] = useState(false);

    const logout = () => {
        localStorage.clear();
        window.location.reload();
        return redirect("/login");
    };

    const updateMenu = () => {
        setMenu(prevMenu => !prevMenu);
    };

    const updateBeds = (selectedProperty) => {
        post('beds/get-all-beds', { property: selectedProperty })
            .then((response) => {
                setMainState({ type: "rooms", payload: response.data });
            })
            .catch((error) => {
                console.error('Error fetching rooms:', error);
            });
    }

    const updateProperty = (s) => {
        setMainState({ type: 'userId', payload: false })
        setMainState({ type: 'bookingId', payload: false })
        setMainState({ type: "selected_Property", payload: s.target.value });
        updateBeds(s.target.value)
    };

    return (
        <>
            <div className="header">
                <div className="nop">
                    <div className="header-menu">
                        <img src={"https://hostelmate.co/images/logo.svg"} alt="logo" />
                        <label className="input-menu" onClick={updateMenu}>
                            <i className="fa-solid fa-bars"></i>
                        </label>
                        <div className={`head-flex ${menu ? 'active' : ''}`}>
                            {mainState.user.is_staff ?
                                <>
                                    <ul>
                                        <li> <NavLink activeclassname="active" to=""> Dashboard</NavLink> </li>
                                        <li> <NavLink activeclassname="active" to="table"> Calendar</NavLink> </li>
                                        <li className="listholder">
                                            <span> Guest </span>
                                            <ul>
                                                <li> <NavLink activeclassname="active" to="add-new-guest">  Add Guest</NavLink> </li>
                                                <li><NavLink activeclassname="active" to="users">View Guest</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink activeclassname="active" to="whatsapp"> Chat</NavLink></li>
                                        <li className="listholder">
                                            <span>Other</span>
                                            <ul>
                                                <li><NavLink activeclassname="active" className={"h-btn"} to="booking-list">Report</NavLink></li>
                                                <li><NavLink activeclassname="active" to="v-cards"> V-Cards</NavLink></li>
                                                <li><NavLink activeclassname="active" to="pricing"> Pricing</NavLink></li>
                                                <li><NavLink activeclassname="active" to="channel-manager">Channel Manager</NavLink></li>
                                                <li><NavLink activeclassname="active" to="waitlist-website"> Website Leads</NavLink></li>
                                                <li><NavLink activeclassname="active" to="payment"> Pending Payments</NavLink></li>
                                                <li><NavLink activeclassname="active" to="current-cash">Current Cash flow</NavLink></li>
                                                <li><NavLink activeclassname="active" to="expenses">Expenses</NavLink></li>
                                                <li><NavLink activeclassname="active" to="booking-logs"> Logs</NavLink></li>
                                                <li><NavLink activeclassname="active" to="door-key"> Door Access</NavLink></li>
                                                <li> <a onClick={logout} href className="red">  Logout</a> </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </>
                                :
                                <>
                                    <ul>
                                        <li> <NavLink activeclassname="active" to=""> Home</NavLink> </li>
                                        <li> <NavLink activeclassname="active" to="table"> Bookings</NavLink> </li>
                                        <li className="listholder">
                                            <span> Guest </span>
                                            <ul>
                                                <li> <NavLink activeclassname="active" to="add-new-guest">  Add Guest</NavLink> </li>
                                                <li><NavLink activeclassname="active" to="users">View Guest</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink activeclassname="active" to="whatsapp"> Chat</NavLink></li>
                                        <li className="listholder">
                                            <span>Other</span>
                                            <ul>
                                                <li><NavLink activeclassname="active" to="user-staff-current-cash">Current Cash flow</NavLink></li>
                                                <li><NavLink activeclassname="active" to="door-key"> Door Access</NavLink></li>
                                                <li> <a href onClick={logout} className="red">  Logout</a> </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </>
                            }
                            <select onChange={updateProperty} defaultValue={mainState.selected_Property}>
                                <ListProperties mainState={mainState} setMainState={setMainState} />
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body">
                <Outlet context={useOutletContext()} />
                <Notifi />
                <Loader />

                {/* Popups */}
                {mainState.bookingId ? <ViewBooking bid={mainState.bookingId} mainState={mainState} /> : false}
                {mainState.userId ? <ViewUser uid={mainState.userId} /> : false}
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

            </div>
        </>
    );
}

export default Main;
