import { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../context/store";
import { post } from "../../api/requests/main.js";

import { useLocation } from 'react-router-dom';


function WhatsaApp() {
    const iframeRef = useRef(null);
    const [noAccess, setNoAccess] = useState(false);
    const parentOrigin = window.location.origin;
    const [wp_data, setwp_data] = useState(null);
    const childOrigin = "https://app-whpfr.hostelmate.co";
    const [mainState, _] = useContext(Context);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phone = searchParams.get('phone');


    useEffect(() => {
        setwp_data(null);
        handleSingleChatDetails()
    }, [mainState.selected_Property])

    const handleSingleChatDetails = async () => {
        try {
            const response = await post('whatsapp/get-wp-info', { property: mainState.selected_Property });
            if (response.status === 200) {
                let data = response.data;
                if (phone) {
                    data = {
                        ...data,
                        activeChat: phone,
                    };
                }
                setwp_data(data);
            } else {
                setNoAccess(true)
            }
        } catch (error) {
            setwp_data(null);
        }
    };


    useEffect(() => {
        function handleChildReady(event) {
            if (event.origin !== childOrigin) return;
            if (event.data && event.data.type === "READY" && wp_data) {
                // console.log("{''}", wp_data)
                if (iframeRef.current) {
                    iframeRef.current.contentWindow?.postMessage(wp_data, childOrigin);
                }
            }
        }

        window.addEventListener("message", handleChildReady);

        return () => {
            window.removeEventListener("message", handleChildReady);
        };
    }, [wp_data]);

    if (noAccess) {
        return (
            <div className='d-center'>
                <h3>Access Restricted</h3>
                <p>Your current plan does not include this feature.</p>
                <p>Contact support to upgrade and gain access.</p>
            </div>
        );
    }

    return (
        <>
            {iframeRef && wp_data &&
                <iframe
                    ref={iframeRef}
                    src={`${childOrigin}/?parentOrigin=${encodeURIComponent(parentOrigin)}`}
                    title="Chat Application"
                    className="whatsapp-iframe"
                />
            }
        </>
    );
}

export default WhatsaApp;