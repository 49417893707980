import React, { useState, useContext, useEffect } from 'react';
import { post } from '../../api/requests/main';
import { ExtraCharge } from '../sub-componments/paymentMethod';
import { Context } from '../../context/store';
import { QRCodeCanvas } from 'qrcode.react';

function NewPaymentLink({ uid, gid, bid, type, newExtraCharge }) {
    const [mainState, setMainState] = useContext(Context);
    const [amount, setAmount] = useState('');
    const [data, setData] = useState(null);
    const [typeNew, setType] = useState(newExtraCharge);

    const initStripe = async () => {
        setMainState({ type: 'loader', payload: true });
        try {
            const response = await post('online/payment-gateway/create-checkout-session', {
                "price": Number(amount) * 100,
                "uid": uid,
                "gid": gid,
                property: mainState.selected_Property,
                "type": newExtraCharge ? typeNew : "booking",
                "payment_gateway": type,
                "bid": bid
            });
            if (response.status === 201 || response.status === 200) {
                setData(response.data.url);
            }
        } catch (error) {
            console.error(`Error creating ${type} link:`, error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };

    return (
        <>
            {data ? (
                <div className='qr-placeholder'>
                    <br />
                    <p className='alert alert-success'>
                        Payment link successfully generated for ({amount})
                    </p>
                    <p>
                        Open the link from <a href={data} target='_blank' rel="noopener noreferrer">here</a> or scan the QR code below:
                    </p>
                    <br />
                    <div className='qr-wrapper'>
                        <QRCodeCanvas value={data} size={400} />
                    </div>
                    <br />
                    <br />
                </div>
            ) : (
                <>
                    <section>
                        <label>Amount:</label>
                        <input
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            name='amount'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className='form-control'
                        />
                        {newExtraCharge && (
                            <>
                                <label>Service:</label>
                                <select
                                    name='name'
                                    className='form-select'
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option></option>
                                    <ExtraCharge />
                                </select>
                                <br />
                            </>
                        )}
                    </section>
                    <footer className='actions'>
                        <button
                            className='btn main btn-primary'
                            onClick={initStripe}
                            disabled={!amount || amount === '0' || typeNew === true || typeNew === ""}
                        >
                            Generate Link
                        </button>
                    </footer>
                </>
            )}
        </>
    );
}

export default NewPaymentLink;
